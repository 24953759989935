import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom';
import Slider from "react-slick";
import $ from 'jquery'

const Resident_Detail = () => {


    const { id } = useParams();
    const [data, SetData] = useState(null);
    const [all, SetSongs] = useState(null);
    const [box, SetBox] = useState(false);
    const [all_shows, SetShowss] = useState(null);

    const GetData = () => {
        axios.get(`https://roughradio.live/admin/api/resident-detail/${id}`).then((res) => {
            SetData(res.data.data);
            SetSongs(res.data.all);
            SetShowss(res.data.all_shows)
        })
    }

    useEffect(() => {
        $('.select-dropdown__button').unbind().on('click', function () {
            if ($('.select-dropdown__list').hasClass('active')) {
                $('.select-dropdown__list').removeClass('active')
            } else {
                $('.select-dropdown__list').addClass('active')
            }
        });
        $('.select-dropdown__list-item').unbind().on('click', function () {
            var itemValue = $(this).data('value');
            $('.select-dropdown__button span').text($(this).text()).parent().attr('data-value', itemValue);
            $('.select-dropdown__list').toggleClass('active');
        });

        // $('.sho-btn').unbind().click(function (e) {
        //     e.preventDefault();
        //     let url = $(this).attr('link');
        //     axios.get(`https://roughradio.live/admin/api${url}`).then((res) => {
        //         if (res.data.songs_data.length !== 0) {
        //             navigate(url);
        //         }
        //     })
        // })
        $(document).ready(function () {
            setTimeout(function () {
                $('[d-src]').each(function () {
                    $(this).attr('src', $(this).attr('d-src'));
                })
            }, 2000)
        })
    })

    const SetAudio = (show_id) => {
        axios.get(`https://roughradio.live/admin/api/resident-detail/${id}?show=${show_id}`).then((res) => {
            localStorage.setItem('audio_data', res.data.songs_list);
            if (res.data.current.show_data_image2_path === null) {
                localStorage.setItem('audio_image', `images/imgnotfound.jpg`);
            } else {
                localStorage.setItem('audio_image', `https://roughradio.live/admin/${res.data.current.show_data_image2_path}/${res.data.current.show_data_image2}`);
            }
            localStorage.setItem('audio_current', JSON.stringify(res.data.current));
            localStorage.setItem('audio_genre', JSON.stringify(res.data.genre_data));
            window.dispatchEvent(new Event('storage'))
            $('.rhap_play-pause-button[aria-label="Play"]').click();
        })
    }

    useEffect(() => {
        GetData();
    }, [id])

    const setting = {
        autoplay: true,
        autoplaySpeed: 5000,
        dots: false,
        arrows: true,
        infinite: true,
        speed: 300,
        slidesToShow: 3,
        slidesToScroll: 1,
        responsive: [{
            breakpoint: 1024,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
            }
        },
        {
            breakpoint: 600,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2
            }
        },
        {
            breakpoint: 480,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1
            }
        }
        ]
    };

    if (data == null || all == null) {
        return <div className='Loading'></div>
    }

    return (
        <>
            <section className="resident-dtl">
                <div className="container-fluid ps-5 pe-5">
                    <div className="row">
                        <div className="col-md-12  ">
                            <div className="resident-dtl-cntnt">
                                <div className="un-head wow bounceInUp">
                                    <h3>RESIDENTS</h3>
                                </div>
                                <div className="bgg_recntt">
                                    <img src='images/loader.gif' d-src={`https://roughradio.live/admin/assets/uploads/banner/${data.artist_image_banner}`} className=" wow bounceInLeft" alt="images" />
                                    <div className="recnt_boxx_new">
                                        <p>{data.artist_time}</p>
                                        <p className={!box && "ellipsis-text"}>{data.artist_desc}</p>
                                        <div className="modal-btn">
                                            <a onClick={() => SetBox(prevBox => !prevBox)}>
                                                {box ? 'Read Less' : 'Read More'}
                                            </a>
                                        </div>
                                        <ul>
                                            {
                                                data.artist_instagram === '' ? false : <li>
                                                    <a href={data.artist_instagram} target="_blank" rel="nofollow">
                                                        <i className="fab fa-instagram" aria-hidden="true" />
                                                    </a>
                                                </li>
                                            }

                                            {
                                                data.artist_soundcloud === '' ? false : <li>
                                                    <a href={data.artist_soundcloud} target="_blank" rel="nofollow">
                                                        <i className="fab fa-soundcloud" aria-hidden="true" />
                                                    </a>
                                                </li>
                                            }

                                            {
                                                data.artist_facebook === '' ? false : <li>
                                                    <a href={data.artist_facebook} target="_blank" rel="nofollow">
                                                        <i className="fab fa-facebook-f" aria-hidden="true" />
                                                    </a>
                                                </li>
                                            }

                                            {
                                                data.artist_music === '' ? false : <li>
                                                    <a href={data.artist_music} target="_blank" rel="nofollow">
                                                        <i className="fas fa-music" aria-hidden="true" />
                                                    </a>
                                                </li>
                                            }

                                        </ul>
                                    </div>
                                </div>
                                <div className="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} aria-labelledby="staticBackdropLabel" aria-hidden="true">
                                    <div className="modal-dialog">
                                        <div className="modal-content modal-wrap">
                                            <div className="modal-header">
                                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                                            </div>
                                            <div className="modal-body">
                                                <div className="second-screen-form">
                                                    <p>{data.artist_desc}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="plyer__sec">
                <div className="container-fluid ps-5 pe-5">
                    <div className="col-12">
                        <div className="un-head wow bounceInUp">
                            <h3>Shows</h3>
                        </div>
                    </div>
                </div>
                <div className="container-fluid ps-5 pe-5">
                    <div className="row">
                        {
                            all.length !== 0 ? all.map((item) => {
                                return <div className="col-md-2">
                                    <div className='show-bossx' onClick={() => { SetAudio(item.show_data_id) }}>
                                        <i className='fas fa-play'></i>
                                        <img src='images/loader.gif' d-src={item.show_data_image2 === null ? 'images/imgnotfound.jpg' : `https://roughradio.live/admin/${item.show_data_image2_path}/${item.show_data_image2}`} />
                                        <h3>{item.show_data_name}
                                            {/* {item.show_data_description !== null ? <p> {item.show_data_description} </p>: false} */}
                                        </h3>

                                    </div>
                                </div>
                            }) : <div className="notfound">No Shows Found</div>
                        }
                    </div>
                </div>
            </section>
            <section className="resident-sec mt-5">
                <div className="container-fluid ps-5 pe-5">
                    <div className="col-12">
                        <div className="un-head wow bounceInUp">
                            <h3>Recent Shows</h3>
                        </div>
                    </div>
                </div>
                <div className="container-fluid ps-5 pe-5 resd_detail resi-sl-cont">
                    <div className="wow bounceInLeft">
                        <Slider className="residnt-sl-mn asdasd" {...setting}>
                            {
                                all_shows !== null ?
                                    all_shows.map((item, key) => {
                                        if (item.show_data_name.toLowerCase() !== "picks from the archive") {
                                            return <div className="resi-inn artist_box" link={`/resident-detail/${item.show_data_artist_id}?show=${item.show_data_id}`}>
                                                {item.show_data_image2 !== null ? <Link to={`/resident-detail/${item.show_data_artist_id}?show=${item.show_data_id}`}>
                                                    <img src='images/loader.gif' d-src={item.show_data_image2 === null ? 'images/imgnotfound.jpg' : `https://roughradio.live/admin/${item.show_data_image2_path}/${item.show_data_image2}`} />
                                                    <div className="artist_listss">
                                                        <ul>
                                                            <li>
                                                                {/* <a href={`/resident-detail/${item.show_data_artist_id}?show=${item.show_data_id}`}> */}
                                                                <h6>{item.show_data_name}</h6>
                                                                {/* </a> */}
                                                            </li>
                                                        </ul>
                                                        <p>13:00 - 23:00</p>
                                                    </div>
                                                </Link> : <>
                                                    <img src='images/loader.gif' d-src={item.show_data_image2 === null ? 'images/imgnotfound.jpg' : `https://roughradio.live/admin/${item.show_data_image2_path}/${item.show_data_image2}`} />
                                                    <div className="artist_listss">
                                                        <ul>
                                                            <li>
                                                                {/* <a href={`/resident-detail/${item.show_data_artist_id}?show=${item.show_data_id}`}> */}
                                                                <h6>{item.show_data_name}</h6>
                                                                {/* </a> */}
                                                            </li>
                                                        </ul>
                                                        <p>13:00 - 23:00</p>
                                                    </div>
                                                </>}
                                            </div>
                                        } else {
                                            return false
                                        }
                                    })
                                    : false
                            }
                        </Slider>
                    </div>
                </div>

            </section>


        </>
    )
}

export default Resident_Detail